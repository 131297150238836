import { formatLabel } from 'src/utils/common'
import styles from './index.module.less'
import { runningStatusList } from 'src/utils/dict'

export default {
  props: {
    data: [Number, String],
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    formatLabel,
  },
  render() {
    const { data, formatLabel, showLabel } = this

    let className = ''

    if ([0].includes(data)) {
      className = 'offline'
    } else if (data === 2) {
      className = 'exception'
    } else if (data === 1) {
      className = 'green'
    }

    return (
      <div class={styles.tableStatus}>
        <i class={`${styles.status} ${styles[className]}`}></i>
        {showLabel ? <span>{formatLabel(runningStatusList, data)}</span> : ''}
      </div>
    )
  },
}
