// 运行状态
export const runningStatusList = [
  {
    label: '在线',
    value: 1,
  },
  {
    label: '离线',
    value: 0,
  },
  {
    label: '异常',
    value: 2,
  },
]

// 分配状态
export const assignStatusList = [
  {
    label: '未分配',
    value: 0,
  },
  {
    label: '已分配',
    value: 1,
  },
]

//盒子类型
export const productTypeList = [
  {
    label: '新零售客流',
    value: 0,
  },
  {
    label: '红外',
    value: 1,
  },
  {
    label: 'NUC广告机',
    value: 2,
  },
  {
    label: '安卓广告机',
    value: 3,
  },
  {
    label: '安防监控',
    value: 4,
  },
  {
    label: '数字哨兵',
    value: 5,
  },
]
